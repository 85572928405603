import { WHITELABEL_THEME_SCHEMA } from '@adtelligent-fe/react-common/src/constants/WHITELABEL_THEME_SCHEMA';

import type { CustomTheme, ThemeSchema, WhitelabelThemeSchema } from './white-label-styling.types';

const ROOT_ELEMENT = document.documentElement;

export const overrideCssVars = (activeTheme: keyof WhitelabelThemeSchema, customTheme: CustomTheme): Promise<void> => {
  return new Promise((resolve) => {
    if (activeTheme === 'custom') {
      Object.entries(customTheme).forEach(([style, color]) => {
        if (color) {
          ROOT_ELEMENT.style.setProperty(`--${style}`, `rgb(${color.r}, ${color.g}, ${color.b})`);
        }
      });
    } else {
      const theme = WHITELABEL_THEME_SCHEMA[activeTheme] as ThemeSchema;

      Object.entries(theme).forEach(([style, value]) => {
        ROOT_ELEMENT.style.setProperty(`--${style}`, value);
      });
    }
    resolve();
  });
};
