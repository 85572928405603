import { useWhiteLabel } from '@adtelligent-fe/react-common/src/modules/white-label';
import { isBidmatic, isWLDomain } from '@adtelligent-fe/react-common/src/modules/white-label/utils/white-label.utils';
import { useFavicon } from 'primereact/hooks';
import { useEffect, useState } from 'react';

import { APP_FAVICON_PATH } from '../constants';

export const useGetFavicon = () => {
  const { domainData } = useWhiteLabel();
  const [favicon, setFavicon] = useState('');

  useEffect(() => {
    if (isBidmatic()) {
      setFavicon(APP_FAVICON_PATH);
    } else if (isWLDomain()) {
      setFavicon(domainData.wl_favicon);
    } else {
      setFavicon('/assets/images/favicon.ico');
    }
  }, [domainData.wl_favicon]);

  useFavicon(favicon, 'icon');
};
