import translationEN from '@adtelligent-fe/react-common/src/i18n/dictionaries/en';

export default {
  ...translationEN,
  'publisher-share-of-revenue': 'Publisher Share of Revenue',
  saas: 'Saas',
  'audio-player': 'Audio player',
  'display-gamless': 'Display Gamless',
  'blocked-advertisers-hint': 'Put comma separated list of domains to block',
  eav: 'Entity Attribute Value',
  'lazy-load': 'Lazy Load',
  'bidmatic-ad-units': 'Bidmatic AdUnits',
  'other-ad-units': 'Other AdUnits',
  'screens-desktop': 'Screens (Desktop)',
  'screens-mobile': 'Screens (Mobile)',
  'ad-refresh': 'Ad Refresh',
  'is-manual-refresh': 'Is Manual Refresh',
  'exclude-gam-line-items-from-refresh': 'Exclude GAM Line Items from Refresh',
  'generate-new': 'Generate New',
  sellers: 'Sellers',
  'download-existing-files': 'Download Existing Files',
  'getting-input-data': 'Getting Input Data',
  'collecting-ads-txt-data': 'Collecting Ads Txt Data',
  'bidmatic-sellers': 'Bidmatic sellers',
  'adtelligent-sellers': 'Adtelligent sellers',
  'bidmatic-remap-sellers': 'Bidmatic remap sellers',
  'adtelligent-remap-sellers': 'Adtelligent remap sellers',
  publish: 'Publish',
  'publish-success-message': 'Changes have been published successfully',
  any: 'Any',
  logs: 'Logs',
  'network-errors': 'Network Errors',
  level: 'Level',
  message: 'Message',
  timestamp: 'Timestamp',
  'publish-warning-message': 'Are you sure you want to download the changes to the file: {{fileName}}?',
  'adtelligent-seller-id': 'Adtelligent Seller ID',
  'bidmatic-seller-id': 'Bidmatic Seller ID',
  'open-ads-txt': 'Open ads.txt',
  'seller-id': 'Seller ID',
  'seller-type': 'Seller Type',
  'is-confidential': 'Is Confidential',
  'header-search-placeholder': 'Keyword Search',
  bsid: 'BSID',
  'custom-mobile-refresh': 'Custom Mobile Refresh',
  'mobile-refresh-interval': 'Mobile Refresh Interval',
  'mobile-subsequent-refresh-interval': 'Mobile Subsequent Refresh Interval',
  'mobile-refresh-when-buyer-is': 'Mobile Refresh When Buyer Is',
  'mobile-buyers-in-refresh': 'Mobile Buyers In Refresh',
  'mobile-refresh-on': 'Mobile Start first auction on',
  'protected-media-tag': 'Protected Media Tag',
  'pixalate-tag': 'Pixalate Tag',
  'moat-tag': 'Moat Tag',
  scorings: 'Scorings',
  files: 'Files',
  format: 'Format',
  download: 'Download',
  'download-files': 'Download Files',
  'published-file-message': 'File has been published successfully',
  'date-of-publication': 'Date of Publication',
  look: 'Look',
  rollback: 'Rollback',
  'rollback-warning-message': 'Are you sure you want to replace the existing version?',
  'copy-seller-id': 'Copy seller id',
  'forgot-password-back-link': 'Go Back',
  'forgot-password': 'Forgot Password',
  'password-error': 'Passwords do not match',
  'please-enter-your-email': 'Please enter your email',
  'back-to-login-page': 'Back to Login Page',
  'please-enter-your-account-details-below': 'Please enter your account details below',
  'forgot-password-supply-side-platform': 'Forgot password Supply Side Platform',
  'error-with-getting-status': 'Error with getting status',
  'add-new-access': 'Add New Access',
  'are-you-sure-you-want': 'Are you sure you want to revoke access for {{username}}?',
  confirmation: 'Confirmation',
  'give-the-access-to-this-person': 'Enter an email address in order to give the access to this person.',
  'global-search': 'Global Search',
  'access-provider': 'Access Provider',
  'mcm-mi-list': 'MCM MI list',
  'ai-video': 'AI Video',
  'add-avatar': 'Add avatar',
  'social-media-format': 'Social media format',
  'regenerate-only-video': 'Regenerate only video',
  'no-records-found': 'No records found',
  'is-test-site': 'Test site',
  'player-configurations': 'Player configurations',
  'youtube-channel': 'YouTube Channel',
  'no-id': 'No ID',
  'try-generate-sellers-again': 'Try to generate sellers again.',
  'blabwire-sellers': 'Blabwire sellers',
  'adlane-sellers': 'Adlane sellers',
  'blabwire-remap-sellers': 'Blabwire remap sellers',
  'adlane-remap-sellers': 'Adlane remap sellers',
  'process-errors': 'Process errors log: {{company}}',
  'show-process-logs': 'Show process logs'
};
