import { KnowledgeBase } from '@adtelligent-fe/react-common/src/components/knowledge-base';
import { Menubar } from 'primereact/menubar';
import React, { type FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import EndHeaderMenu from './components/header-navbar-end.component';
import StartHeaderMenu from './components/header-navbar-start.component';
import { getMenuItem } from './dictionaries';

export const HeaderMenu: FC = () => {
  const { t } = useTranslation();
  const [knowledgeBaseVisible, setKnowledgeBaseVisible] = useState(false);
  const menuItems = useMemo(() => getMenuItem({ t }), [t]);

  return (
    <>
      <Menubar
        model={menuItems}
        start={<StartHeaderMenu />}
        end={<EndHeaderMenu action={setKnowledgeBaseVisible} />}
        className="z-[1] justify-between py-6"
      />
      <KnowledgeBase isVisible={knowledgeBaseVisible} setVisible={setKnowledgeBaseVisible} />
    </>
  );
};
