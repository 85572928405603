import { UserInfo } from '@adtelligent-fe/react-common/src/components/user-info';
import { useAppDispatch } from '@adtelligent-fe/react-common/src/hooks/app-dispatch.hook';
import { AuthReducers } from '@adtelligent-fe/react-common/src/modules/auth';
import { useLogoutMutation } from '@adtelligent-fe/react-common/src/services/auth/auth.api';

import lodashGet from 'lodash/get';
import React, { type Dispatch, type SetStateAction } from 'react';
import { CiSettings } from 'react-icons/ci';
import { IoIosHelpCircleOutline } from 'react-icons/io';
import { IoLogOutOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../hooks/use-app-selector.hook';

interface EndHeaderMenuEndProps {
  action: Dispatch<SetStateAction<boolean>>;
}

const EndHeaderMenu = ({ action }: EndHeaderMenuEndProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [logout] = useLogoutMutation();
  const { authData } = useAppSelector((state) => state.auth);

  const onLogoutClick = async (): Promise<void> => {
    const sid = lodashGet(authData, ['sessionData', 'sid']);

    if (sid) {
      await logout(sid);
    }

    dispatch(AuthReducers.clearState());
    navigate('/auth');
  };

  return (
    <div className="flex gap-6 items-center">
      <UserInfo className="flex gap-3 items-center" />

      {/*<AppNotifications*/}
      {/*  icon={<MdNotifications size={24} className="" />}*/}
      {/*  socketUrl={getCompiledServiceLink(API_ENDPOINT_KEY.NOTIFICATION_SOCKET)}*/}
      {/*/>*/}
      <CiSettings
        size={24}
        onClick={() => navigate('/settings')}
        className="mx-1 text-[20px] hover:text-primary hover:cursor-pointer"
      />
      <IoIosHelpCircleOutline
        size={24}
        onClick={() => action(true)}
        className="mx-1 text-[20px] hover:text-primary hover:cursor-pointer"
      />
      <IoLogOutOutline size={24} className="cursor-pointer hover:stroke-primary" onClick={() => onLogoutClick()} />
    </div>
  );
};

export default EndHeaderMenu;
