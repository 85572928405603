import type { TFunction } from 'react-i18next';

import ItemTemplate from '../components/header-navbar-item-template.component';

interface MenuItem {
  label: string;
  url: string;
}

interface MenuItemProps {
  t: TFunction;
}

interface TransformedMenuItem extends MenuItem {
  template: typeof ItemTemplate;
}

const data: MenuItem[] = [
  {
    label: 'mcm-mi-list',
    url: '/ml-adunit-list'
  },
  {
    label: 'clients',
    url: '/clients'
  },
  {
    label: 'publishers',
    url: '/publishers'
  },
  {
    label: 'sites',
    url: '/sites'
  },
  {
    label: 'global-settings',
    url: '/global-settings'
  },
  {
    label: 'sellers',
    url: '/sellers'
  },
  {
    label: 'ai-video',
    url: '/ai-video'
  }
];

export const getMenuItem = ({ t }: MenuItemProps): TransformedMenuItem[] => {
  return data.map((item) => ({
    label: t(item.label),
    url: item.url,
    template: ItemTemplate
  }));
};
