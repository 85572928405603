import { useAppDispatch } from '@adtelligent-fe/react-common/src/hooks/app-dispatch.hook';

import { Dialog } from 'primereact/dialog';
import React from 'react';

import { injectReducers } from '@adtelligent-fe/react-common/src/store';
import { useAppSelector } from '../../hooks/use-app-selector.hook';
import { selectCustomDialogSlice } from './custom-dialog.selector';
import customDialogSlice, { closeDialog } from './custom-dialog.slice';

injectReducers([{ name: 'customDialogSlice', reducer: customDialogSlice }]);

const CustomDialogComponent = () => {
  const { isOpened, template, title, onCloseCallBack, customClassName, width } =
    useAppSelector(selectCustomDialogSlice);
  const dispatch = useAppDispatch();

  const onHide = () => {
    onCloseCallBack?.();
    dispatch(closeDialog());
  };

  const dialogHeader = () => <div>{title}</div>;

  return (
    <Dialog
      className={customClassName}
      position="top"
      style={{ width: width ? width : '' }}
      draggable={false}
      visible={isOpened}
      resizable={false}
      header={dialogHeader}
      onHide={onHide}
    >
      <div>{template}</div>
    </Dialog>
  );
};

export default CustomDialogComponent;
