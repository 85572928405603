import './i18n';
import './assets/styles/index.scss';

import ThemeProvider from '@adtelligent-fe/react-common/src/components/theme-provider';
// import { APP_NAME } from '@adtelligent-fe/react-common/src/constants/APP_NAME';
import reportWebVitals from '@adtelligent-fe/react-common/src/report-web-vitals';
import * as serviceWorkerRegistration from '@adtelligent-fe/react-common/src/service-worker-registration';
import store, { persistor } from '@adtelligent-fe/react-common/src/store';
// import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { themeProviderConfig } from './components/theme-provider';
import App from './modules/app';

// if (import.meta.env.NODE_ENV === 'production') {
//   Sentry.init({
//     dsn: SENTRY_DSN,
//     release: `${APP_NAME.SSP_MEMBER_ZONE}@${VERSION.package}`
//   });
// }
// setCookie('env', 'prod');

createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <ThemeProvider ptObj={themeProviderConfig}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
