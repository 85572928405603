import classNames from 'classnames';
import type { MenuItem, MenuItemOptions } from 'primereact/menuitem';
import React from 'react';

const ItemTemplate = (item: MenuItem, options: MenuItemOptions) => {
  return (
    <a
      href={item.url}
      className={classNames(options.className, {
        active: item.url && location.pathname.includes(item.url)
      })}
      target={item.target}
      data-pr-tooltip={item.label}
    >
      <span className={options.labelClassName}>{item.label}</span>
    </a>
  );
};

export default ItemTemplate;
