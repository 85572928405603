import React from 'react';

import { APP_LOGO_PATH } from '../../../modules/app/constants';

const StartHeaderMenu = () => {
  return (
    <a className="flex items-center" href="/sites">
      <img className="max-w-[150px] max-h-full" alt="App logo" src={APP_LOGO_PATH} />
    </a>
  );
};

export default StartHeaderMenu;
