import SuspenseFallback from '@adtelligent-fe/react-common/src/components/suspense-fallback';
import { usePermissions } from '@adtelligent-fe/react-common/src/hooks/use-permissions.hook';
import { useRouteProtection } from '@adtelligent-fe/react-common/src/hooks/use-route-protection.hook';
import type { MenuItem } from 'primereact/menuitem';
import React, { type FC } from 'react';
import { Navigate } from 'react-router-dom';

import ProtectedLayout from '../protected-layout';

interface ProtectedRoute {
  permissions?: string[];
  contentLayout?: FC;
  sidebarItems?: MenuItem[];
}

export const ProtectedRouteWithLayout: FC<ProtectedRoute> = ({ permissions, contentLayout, sidebarItems }) => {
  const { isPermitted } = usePermissions(permissions);
  const { isAuthenticated } = useRouteProtection();

  if (isAuthenticated === null) {
    return <SuspenseFallback />;
  } else if (isAuthenticated === true) {
    if (isPermitted === false) {
      return <Navigate to="/" />;
    }

    return <ProtectedLayout contentLayout={contentLayout} sidebarItems={sidebarItems} />;
  }

  return <Navigate to="/auth" />;
};
