import GlobalToast from '@adtelligent-fe/react-common/src/components/global-toast';
import SuspenseFallback from '@adtelligent-fe/react-common/src/components/suspense-fallback';
import { useAppDispatch } from '@adtelligent-fe/react-common/src/hooks/app-dispatch.hook';
import { setAuthData } from '@adtelligent-fe/react-common/src/modules/auth/store/auth.slice';
import { isBidmatic } from '@adtelligent-fe/react-common/src/modules/white-label/utils/white-label.utils';
import { useLazySignInBySessionQuery } from '@adtelligent-fe/react-common/src/services/auth/auth.api';
import lodash_get from 'lodash/get';
import { ConfirmDialog } from 'primereact/confirmdialog';
import React, { type FC, Suspense, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';

import CustomDialogComponent from '../../components/custom-dialog';
import { useGetFavicon } from './hooks/use-app.hooks';
import MainPage from './pages/main.page';
import { overrideCssVars } from './utils/white-label-styling/override-css-vars';

const Auth = React.lazy(() => import('../auth'));
const Clients = React.lazy(() => import('../clients'));
const Publishers = React.lazy(() => import('../publishers'));
const GlobalSettings = React.lazy(() => import('../global-settings'));
const Sellers = React.lazy(() => import('../sellers'));
const MlAudits = React.lazy(() => import('../ml-adunits-list'));
const Sites = React.lazy(() => import('../sites'));
const SellersPage = React.lazy(() => import('../sellers'));
const AiVideoPage = React.lazy(() => import('../ai-video'));

const App: FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [signInBySession] = useLazySignInBySessionQuery();
  const [ready, setReady] = useState(true);

  useEffect(() => {
    const sessionId = searchParams.get('sessionId');

    if (sessionId) {
      signInBySession(sessionId).then((res) => {
        if (lodash_get(res, 'data.success')) {
          dispatch(setAuthData(lodash_get(res, 'data.data')));
        }
      });
    }
  }, []);

  useEffect(() => {
    if (isBidmatic()) {
      overrideCssVars('bidmatic', {} as any)
        .catch(console.error)
        .then(() => setReady(true));
    } else {
      overrideCssVars('basic', {} as any)
        .catch(console.error)
        .then(() => setReady(true));
    }
  }, []);
  useGetFavicon();

  return (
    <Suspense fallback={<SuspenseFallback />}>
      {!ready ? <SuspenseTrigger /> : null}
      <Routes>
        <Route index element={<MainPage />} />
        <Route path="/auth/*" element={<Auth />} />
        <Route path={'/ml-adunit-list/*'} element={<MlAudits />} />
        <Route path={'/clients/*'} element={<Clients />} />
        <Route path={'/sites/*'} element={<Sites />} />
        <Route path={'/publishers/*'} element={<Publishers />} />
        <Route path={'/global-settings/*'} element={<GlobalSettings />} />
        <Route path={'/sellers/*'} element={<Sellers />} />
        <Route path={'/sellers/*'} element={<SellersPage />} />
        <Route path={'/ai-video/*'} element={<AiVideoPage />} />
        <Route path="/" element={<MainPage />} />
        <Route path="*" element={<Navigate to="ai-video/site/" />} />
      </Routes>
      <GlobalToast />
      {/*<AdBlock />*/}
      {/*<UnsupportedBrowser />*/}
      {/*<GdprCheck />*/}
      <ConfirmDialog />
      <CustomDialogComponent />
    </Suspense>
  );
};
const SuspenseTrigger = () => {
  throw new Promise(() => {});
};

export default App;
