import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { ReactElement } from 'react';

export interface CustomDialogProps {
  isOpened: boolean;
  title: string;
  template: null | ReactElement;
  width: string;
  onCloseCallBack: (() => void) | null;
  customClassName: string;
}
const initialState: CustomDialogProps = {
  isOpened: false,
  title: '',
  template: null,
  width: '80vw',
  onCloseCallBack: null,
  customClassName: 'w-full lg:w-2/3 2xl:w-1/2 pb-3'
};

const customDialogSlice = createSlice({
  name: 'customDialogSlice',
  initialState,
  reducers: {
    openDialog: (state, action: PayloadAction<Partial<CustomDialogProps>>) => {
      return { ...state, ...action.payload, isOpened: true };
    },
    closeDialog: () => {
      return initialState;
    }
  }
});

export default customDialogSlice.reducer;

export const { openDialog, closeDialog } = customDialogSlice.actions;
